import React from "react"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import Stepper from "@material-ui/core/Stepper"
import Step from "@material-ui/core/Step"
import StepLabel from "@material-ui/core/StepLabel"
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline"
import StepConnector from "@material-ui/core/StepConnector"
import clsx from "clsx"
const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 22,
  },
  active: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  completed: {
    "& $line": {
      backgroundImage:
        "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: "#eaeaf0",
    borderRadius: 1,
  },
})(StepConnector);

const useColorlibStepIconStyles = makeStyles({
  root: {
    backgroundColor: "#ccc",
    zIndex: 1,
    color: "#fff",
    width: 50,
    height: 50,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
  active: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  },
  completed: {
    backgroundImage:
      "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
  },
  formGroup: {
    marginBottom: "15px",
  },
});


function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles()
  const { active, completed } = props
  const icons = {
    1: <CheckCircleOutlineIcon />,
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[1]}
    </div>
  )
}

const StepperSeguimientoComponent = ({ FasePeso, FasesSeguimiento, TipoIndice }) => {

  const fases = FasesSeguimiento.filter(func => func.tipo === TipoIndice).sort((a, b) => (a.peso_fase > b.peso_fase) ? 1 : -1)

  const fasei = fases.findIndex((i) => Number(i.peso_fase) === Number(FasePeso))

  return (
    <Stepper
      className="step-ss"
      alternativeLabel
      activeStep={fasei}
      connector={<ColorlibConnector />}
    >
      {fases.map(fase => (
        <Step key={fase.peso_fase}>
          <StepLabel StepIconComponent={ColorlibStepIcon} className="font-weight-bold">
            <b>{fase.nombre_fase}</b>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  )
}

export default StepperSeguimientoComponent