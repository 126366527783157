import React, { useEffect, useState } from "react"
import Button from "react-bootstrap/Button"
import Table from "react-bootstrap/Table"
import Modal from "react-bootstrap/Modal"
import { useSelector, useDispatch } from "react-redux"
import { ToastContainer, toast } from "react-toastify"
import { navigate } from "gatsby"
import {
  ConsultasReclamosIfF,
  ConsultasReclamosIfPDFF,
  ConsultasReclamosIpPDFF,
  ConsultasMediacionesIpPDFF,
  GetFolioReclamoF,
  ConsultasManejoPDFF
  ,SetCurrentTramites
  ,SetLastTotal
} from "../../../state/actions/Dashboard/ReclamosTramitesActions"
import { GetExpedientePDF } from '../../../state/actions/Dashboard/ExpedienteActions';
import moment from 'moment';
import StepperSeguimientoComponent from './StepperSeguimiento';
import { VerificarExistentesAR } from "../../../state/actions/AntecedentesActions"
import { Box } from "@material-ui/core/"
import { Info } from "@material-ui/icons"
import ModalExistente from './ModalExistente';
const { format } = require("../../../Utils/ValidateRut")

const DetalleSeguimientoComponent = ({ location }) => {
  const [Descargando, setDescargando] = useState(false)
  const [AlertExito, setAlertExito] = useState("")
  const [ShowModalExito, setShowModalExito] = useState(false);
  const [ShowModal, setShowModal] = useState(false);
  const [TipoModal, setTipoModal] = useState(0)
  const [FolioModal, setFolioModal] = useState(0)

  const [Follio, setFollio] = useState(0)
  const [Tipo, setTipo] = useState("")
  const [Intendency, setIntendency] = useState("")
  const dispatch = useDispatch()

  //si el usuario llego a esta pagina redirigido desde validacion.js, existe una lista en el local storage, se procede a cargar datos
  const lista = localStorage.getItem("lista");
  if (lista) {
    localStorage.removeItem("lista");
    const fila = JSON.parse(lista);
    dispatch(SetCurrentTramites(fila))
    dispatch(SetLastTotal(1))
    const nreclamo = fila[0].n_reclamo
    const intendencia = fila[0].intendencia
    const tipo = fila[0].tipo
    const FolioAntecedenteF = () => {
      dispatch(
        GetFolioReclamoF({
          data: { folio: nreclamo, intendencia: intendencia },
        })
      )
    }
    localStorage.setItem("folio", nreclamo)
    localStorage.setItem("intendencia", intendencia)
    localStorage.setItem("tipo", tipo)
    FolioAntecedenteF()
  }

  useEffect(() => {

    if (localStorage.getItem('exitoso') !== "" && localStorage.getItem('exitoso') !== null) {
      setShowModalExito(true)
      setAlertExito(localStorage.getItem('exitoso'))
      localStorage.removeItem('exitoso')
    }

    if (ReclamosTramites.length === 0) {
      const ConsultaReclamosIf = () => dispatch(ConsultasReclamosIfF())
      ConsultaReclamosIf()
    }
    setIntendency(localStorage.getItem('intendencia'))
    setFollio(localStorage.getItem('folio'))
    setTipo(localStorage.getItem('tipo'))

    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [])

  const ReclamosTramites = useSelector(
    state => state.ReclamosTramites.currentTramites
  )

  const PDFDescargadosIf = useSelector(state => state.ReclamosTramites.PDFDescargadosIf)
  const PDFDescargadosIp = useSelector(state => state.ReclamosTramites.PDFDescargadosIp)
  const FasesSeguimiento = useSelector(state => state.ReclamosTramites.FasesSeguimiento)
  const PDFDescargadosExpediente = useSelector(state => state.ExpedienteReducer.PDFDescargadosExpediente)

  const DescargaCertificado = nreclamo => {
    if (Tipo === 'Solicitud') {
      
      const ConsultaDescargaCertificadoManejo = () =>
        dispatch(
          ConsultasManejoPDFF(nreclamo)
        )
      ConsultaDescargaCertificadoManejo()
      return;

    } else if (Intendency === "IF") {

      
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'UA-3535013-1');
      gtag("event", "Inicio de la solicitud de Certificado de reclamo en trámite", {
        event_category: "Certificado de reclamo en trámite",
        event_label: "Trámite ID - 0300520003"
      });
      gtag("event", "Fin de la solicitud de Certificado de reclamo en trámite", {
        event_category: "Certificado de reclamo en trámite",
        event_label: "Trámite ID - 0300520003"
      });
      

      if (PDFDescargadosIf !== null && PDFDescargadosIf !== undefined) {

        let pdf = PDFDescargadosIf.find(func => Number(func.folio) === Number(nreclamo))

        if (pdf !== undefined && pdf !== null) {

          const link = document.createElement("a")
          link.setAttribute(
            "href",
            "data:application/octet-stream;base64," +
            encodeURIComponent(pdf.pdf_text)
          )
          link.setAttribute("download", "reclamo_" + nreclamo + ".pdf")
          link.style.display = "none"
          document.body.appendChild(link)
          link.click()
          toast.success("Descarga exitosa.")

        } else {

          const ConsultaDescargaCertificado = () =>
            dispatch(ConsultasReclamosIfPDFF(nreclamo, setDescargando, PDFDescargadosIf))
          ConsultaDescargaCertificado()
        }
      } else {

        const ConsultaDescargaCertificado = () =>
          dispatch(ConsultasReclamosIfPDFF(nreclamo, setDescargando, PDFDescargadosIf))
        ConsultaDescargaCertificado()
      }

    } else {



      if (PDFDescargadosIp !== null && PDFDescargadosIp !== undefined) {

        
    

        let pdf = PDFDescargadosIp.find(func => Number(func.folio) === Number(nreclamo))

        if (pdf !== undefined && pdf !== null) {

          window.dataLayer = window.dataLayer || [];
          function gtag(){window.dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'UA-3535013-1');
          gtag("event", "Inicio de la solicitud de Certificado de reclamo en trámite", {
            event_category: "Certificado de reclamo en trámite",
            event_label: "Trámite ID - 0300520003"
          });     
           gtag("event", "Fin de la solicitud de Certificado de reclamo en trámite", {
            event_category: "Certificado de reclamo en trámite",
            event_label: "Trámite ID - 0300520003"
          });
          
          const link = document.createElement("a")
          link.setAttribute(
            "href",
            "data:application/octet-stream;base64," +
            encodeURIComponent(pdf.pdf_text)
          )
          link.setAttribute("download", "reclamo_" + nreclamo + ".pdf")
          link.style.display = "none"
          document.body.appendChild(link)
          link.click()
          toast.success("Descarga exitosa.")

        } else {
          if (Tipo === "Mediación") {
            const ConsultaDescargaCertificadoMed = () =>
              dispatch(ConsultasMediacionesIpPDFF(nreclamo, setDescargando, PDFDescargadosIp))
            ConsultaDescargaCertificadoMed()
          } else {
            
               
      window.dataLayer = window.dataLayer || [];
      function gtag(){window.dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'UA-3535013-1');
      gtag("event", "Inicio de la solicitud de Certificado de reclamo en trámite", {
        event_category: "Certificado de reclamo en trámite",
        event_label: "Trámite ID - 0300520003"
      });     
       gtag("event", "Fin de la solicitud de Certificado de reclamo en trámite", {
        event_category: "Certificado de reclamo en trámite",
        event_label: "Trámite ID - 0300520003"
      });

            const ConsultaDescargaCertificado = () =>
              dispatch(ConsultasReclamosIpPDFF(nreclamo, setDescargando, PDFDescargadosIp))
            ConsultaDescargaCertificado()
          }


        }
      } else {
        const ConsultaDescargaCertificado = () =>
          dispatch(ConsultasReclamosIpPDFF(nreclamo, setDescargando, PDFDescargadosIp))
        ConsultaDescargaCertificado()
      }
    }
  }

  const [Expedienteloading, setExpedienteloading] = useState(false);

  const DescargaExpediente = (data) => {
    setExpedienteloading(true)

    if (PDFDescargadosExpediente !== null && PDFDescargadosExpediente !== undefined) {

      let pdf = PDFDescargadosExpediente.find(func => Number(func.folio) === Number(data.n_reclamo))

      if (pdf !== undefined && pdf !== null) {


        const url = window.URL.createObjectURL(
          new Blob([pdf.pdf_text]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `Expediente-${pdf.folio}.pdf`,
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // Clean up and remove the link
        link.parentNode.removeChild(link);
        setExpedienteloading(false)
        toast.success("Descarga exitosa.")

      } else {

        const ConsultaDescargaExpediente = () =>
          dispatch(GetExpedientePDF(data, setExpedienteloading, PDFDescargadosExpediente))
        ConsultaDescargaExpediente()
      }
    } else {

      const ConsultaDescargaExpediente = () =>
        dispatch(GetExpedientePDF(data, setExpedienteloading, PDFDescargadosExpediente))
      ConsultaDescargaExpediente()
    }

  }

  const handleAntecedentes = folio => {

    const FolioAntecedenteF = () => dispatch(GetFolioReclamoF({ data: { "folio": folio } }))
    FolioAntecedenteF()

    localStorage.setItem("folio", folio)
    navigate('/inicio/tramites/detalle/agregar-antecedentes/')
  }

  const handleRecursos = (folio, intendencia) => {
    const FolioAntecedenteF = () => dispatch(GetFolioReclamoF({ data: { "folio": folio, "intendencia": intendencia } }))
    FolioAntecedenteF()

    localStorage.setItem("folio", folio)
    localStorage.setItem("intendencia", intendencia)
    localStorage.setItem("pathname", location.pathname)
    navigate('/inicio/tramites/detalle/agregar-recurso/')
  }

  const handleModal = (data, tipo) => {

    localStorage.setItem("intendencia", data.intendencia)
    let DataF = ReclamosTramites.filter(func => Number(func.n_reclamo) === Number(data.n_reclamo) && func.intendencia === Intendency);

    setTipoModal(tipo)
    setFolioModal(data.n_reclamo)
    const VerificarF = () =>
      dispatch(
        VerificarExistentesAR(
          DataF,
          tipo,
          setShowModal,
          handleAntecedentes,
          handleRecursos
        )
      )
    VerificarF()

  }

  const fasesArchivado = [
    {nombre_fase: "Admisibilidad",
    peso_fase: "100",
    tipo: "APB"},
    {nombre_fase: "Reclamo archivado",
    peso_fase: "200",
    tipo: "APB"}
  ]

  const b64toBlob = (base64, type = 'application/pdf') => {
    return fetch(`data:${type};base64,${base64}`).then(res => res.blob())
  }

  const abrirPdf = async (data, folio) => {
    const blob = await b64toBlob(data)
    var link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    // link.href = url;
    link.setAttribute(
      'download',
      `Expediente-${folio}.pdf`,
    );
    link.dispatchEvent(new MouseEvent('click'));
  }

  return (
    <>

      <ToastContainer closeOnClick={true} pauseOnHover={true} />
      <div className="contenedor">

          <>
            <div className="col-12 d-flex my-2">
              <h1 className="mx-auto titulo-antecedentes-ss">
                Detalles del trámite: {Follio}
              </h1>
            </div>
              
            {ReclamosTramites.filter(func => String(func.n_reclamo) === String(Follio) && func.intendencia === Intendency).map(
              (dato, i) => (
                dato.tipo_indice && (dato.fase.nombre!=='Archivado' && dato.fase.nombre!=='No corresponde a Comnicación de Adecuación') ?
                  <div key={i}>
                    <StepperSeguimientoComponent FasePeso={dato.fase.peso} FasesSeguimiento={FasesSeguimiento} TipoIndice={dato.tipo_indice = "GES" ? "APB" : dato.tipo_indice} Intendencia={dato.intendencia} />
                    <Box style={{padding: "0 1rem", margin:".5rem 0"}}>
                      <div class="alert alert-info" role="alert">
                        <h6 class="alert-heading d-flex align-items-center"> <Info/>&nbsp;Descripción de etapa actual del trámite ({dato.fase.nombre}):</h6>
                        { dato.fase.descripcion_fase }
                      </div>
                    </Box>
                  </div>
                :
                  <div key={i}>
                    <StepperSeguimientoComponent FasePeso={200} FasesSeguimiento={fasesArchivado} TipoIndice={dato.tipo_indice = "GES" ? "APB" : dato.tipo_indice} Intendencia={dato.intendencia} />
                    <Box style={{padding: "0 1rem", margin:".5rem 0"}}>
                      <div class="alert alert-info" role="alert">
                        <h6 class="alert-heading d-flex align-items-center"> <Info/>&nbsp;Descripción de etapa  actual del trámite ({dato.fase.nombre}):</h6>
                        { dato.fase.descripcion_fase }
                      </div>
                    </Box>
                  </div>
              )
            )}

            <Table striped bordered hover size="sm">
              {ReclamosTramites.filter(func => String(func.n_reclamo) === String(Follio) && func.intendencia === Intendency).map(
                (dato, i) => (
                  <tbody key={i}>
                    <tr>
                      <th scope="row">Run {dato.indice_rec !== "manejo_solicitudes" && "reclamante"}:</th>
                      <td>{format(dato.run_rec)}</td>
                    </tr>
                    <tr>
                      <th scope="row">Nombre Completo {dato.indice_rec !== "manejo_solicitudes" && "del reclamante"}:</th>
                      <td>{dato.nombre_rec}</td>
                    </tr>
                    <tr>
                      <th scope="row">Tramitación:</th>
                      <td>{dato.tramitacion}</td>
                    </tr>

                    <tr>
                      <th scope="row">Folio:</th>
                      <td>{dato.n_reclamo}</td>
                    </tr>

                    <tr>
                      <th scope="row">Etapa actual:</th>
                      <td>{dato.etapa_actual}</td>
                    </tr>
                    <tr>
                      <th scope="row">Fecha de ingreso:</th>
                      <td>
                        {moment(dato.fecha_ingreso.split('/')[2] + dato.fecha_ingreso.split('/')[1] + dato.fecha_ingreso.split('/')[0]).format('DD-MM-YYYY')}</td>
                    </tr>

                    {/* {dato.fecha_esperada_termino &&
                      <tr>
                        <th scope="row">Fecha aproximada de respuesta sin considerar recursos:</th>
                        <td>
                          {moment(dato.fecha_esperada_termino.split('/')[2] + dato.fecha_esperada_termino.split('/')[1] + dato.fecha_esperada_termino.split('/')[0]).format('DD-MM-YYYY')}</td>
                      </tr>
                    } */}

                    <tr>
                      <th scope="row">
                        Entidad Requerida:
                </th>
                      <td>
                        {dato.reclamado}
                      </td>
                    </tr>

                    {dato.run_rep !== "" ? (
                      <>
                        <tr>
                          <th scope="row">Run Representante:</th>
                          <td>{dato.run_rep}</td>
                        </tr>
                        <tr>
                          <th scope="row">Nombre Completo del representante:</th>
                          <td>{dato.nombre_rep}</td>
                        </tr>
                      </>
                    ) : null}

                    {dato.run_pac !== "" && dato.run_pac !== null ? (
                      <>
                        <tr>
                          <th scope="row">Run Paciente:</th>
                          <td>{dato.run_pac}</td>
                        </tr>
                        <tr>
                          <th scope="row">Nombre Completo del paciente:</th>
                          <td>{dato.nombre_pac}</td>
                        </tr>
                      </>
                    ) : null}

                    {dato.materia !== "" ? (
                      <tr>
                        <th scope="row">Materia reclamada:</th>
                        <td>{dato.materia}</td>
                      </tr>
                    ) : null}

                    {dato.sub_materia !== "" ? (
                      <tr>
                        <th scope="row">Submateria reclamada:</th>
                        <td>{dato.sub_materia}</td>
                      </tr>
                    ) : null}

                    {/* {dato.expedienteb64 !== "" &&
                      <tr>
                        <th scope="row">Acceso al expediente del reclamo:</th>
                        <td>
                          <button
                            type="button"
                            className="btn btn-notif d-flex mx-auto my-auto text-light"
                            onClick={() => abrirPdf(dato.expedienteb64, dato.n_reclamo)}
                          >
                            Descargar
                          </button>

                        </td>
                      </tr>
                    } */}

                    {dato.link_expediente &&
                      <tr>
                        <th scope="row">Acceso al expediente del reclamo:</th>
                        <td>
                          <button
                            type="button"
                            className="btn btn-notif d-flex mx-auto my-auto text-light"
                            onClick={() => DescargaExpediente(dato)}
                            disabled={Expedienteloading}
                          >
                            Descargar
                    {Expedienteloading && (
                              <div className="spinner-border spinner-border-sm ml-2" role="status">
                                <span className="sr-only">Loading...</span>
                              </div>
                            )}
                          </button>
                        </td>
                      </tr>
                    }

                    {dato.is_antecedente_upload !== null &&
                      <tr>
                        <th scope="row">Antecedentes Adicionales:</th>
                        <td>
                          <button
                            type="button"
                            className="btn btn-notif d-flex mx-auto my-auto text-light"
                            onClick={() => handleModal(dato, 'antecedente')}
                            disabled={dato.is_antecedente_upload ? false : true}
                          >
                            {dato.is_antecedente_upload ? 'Ingresar' : `${dato.is_antecedente_upload_str ? dato.is_antecedente_upload_str : "No es posible en la etapa actual"}`}
                          </button>

                        </td>
                      </tr>
                    }

                    {dato.is_recurso_upload !== null &&
                      <tr>
                        <th scope="row">Presentar Recurso:</th>
                        <td>
                          <button
                            type="button"
                            className="btn btn-notif d-flex mx-auto my-auto text-light"
                            onClick={() =>
                              handleModal(dato, 'recurso')
                            }
                            disabled={dato.is_recurso_upload ? false : true}
                          >
                            {dato.is_recurso_upload ? 'Ingresar' : `${dato.is_recurso_upload_str ? dato.is_recurso_upload_str : "No es posible en la etapa actual"}`}
                          </button>


                        </td>
                      </tr>
                    }
                  </tbody>
                )
              )}
            </Table>

            {ReclamosTramites.filter(func => String(func.n_reclamo) === String(Follio) && func.intendencia === Intendency).map(
              (dato, i) => (
                (dato.fase.nombre!=='Archivado' && dato.fase.nombre!=='No corresponde a Comnicación de Adecuación') &&
                <Button
                  type="button"
                  className="btn btn-notif d-flex mx-auto my-3"
                  onClick={() => DescargaCertificado(String(Follio))}
                  disabled={Descargando || !dato.print_certificate}
                  key={i}
                >
                  { dato.print_certificate ? "Descargar Certificado" : "Certificado no disponible en la etapa actual"}

                  {Descargando && (
                    <div className="spinner-border spinner-border-sm ml-2" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  )}
                </Button>
              ))}

            {ShowModal &&
              <ModalExistente
                ShowModal={ShowModal}
                setShowModal={setShowModal}
                Folio={FolioModal}
                TipoModal={TipoModal}
                handleAntecedentes={handleAntecedentes}
                handleRecursos={handleRecursos}
              />
            }

            <Modal show={ShowModalExito} onHide={() => false}>
              <Modal.Header closeButton>
                <Modal.Title>Documento recibido</Modal.Title>
              </Modal.Header>
              <Modal.Body>{AlertExito}</Modal.Body>
              <Modal.Footer>
                <Button variant="primary" onClick={() => setShowModalExito(false)}>
                  Aceptar
                </Button>
              </Modal.Footer>
            </Modal>

          </>

      </div>
    </>

  )
}

export default DetalleSeguimientoComponent