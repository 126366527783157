import React, { useEffect } from "react"
import LayoutProvider from "../../../components/Layout"
import { navigate } from 'gatsby';
import DetalleSeguimiento from "../../../components/ReclamosComponents/Tabla/DetalleSeguimiento";

const ReclamoPage = ({ location }) => {

  useEffect(() => {
    const logueado = localStorage.getItem("logueado")
    const requiere_actualizar = localStorage.getItem("requiere_actualizar")

    if (logueado === false || logueado === null || logueado === undefined) {
        navigate("/")
    } else if (requiere_actualizar === true || requiere_actualizar === "true") {
        navigate("/inicio/editar-perfil/")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps

  }, [])

  

  return (
    <LayoutProvider title="Reclamos " location={location}>

            <DetalleSeguimiento location={location} />

    </LayoutProvider>
  )
}

export default ReclamoPage
