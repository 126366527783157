import { GET_PDF_DESCARGADOS_EXPEDIENTE } from "../../types"
import { toast } from "react-toastify"
//import * as Sentry from '@sentry/gatsby';

const Get_PDF_EXPEDIENTE_F = datos => ({
  type: GET_PDF_DESCARGADOS_EXPEDIENTE,
  payload: datos,
})


export function GetExpedientePDF(data, setExpedienteloading, PDFDescargadosExpediente) {
  return async (dispatch) => {
    try {
    
      fetch(`${process.env.GATSBY_LOCAL_HOST}PdfExpediente/${data.link_expediente}/${data.sistema_origen}/${data.intendencia}/${data.run_rec}/${data.n_reclamo}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/pdf',
        },
      })
      .then((response) => response.blob())
      .then((blob) => {
                
      if(PDFDescargadosExpediente === [] || PDFDescargadosExpediente === "" ){
        dispatch(Get_PDF_EXPEDIENTE_F([{
          folio: data.n_reclamo,
          pdf_text: blob
        }]))
      }else{
  
        PDFDescargadosExpediente.push({
          folio: data.n_reclamo,
          pdf_text: blob
        })
        dispatch(Get_PDF_EXPEDIENTE_F(PDFDescargadosExpediente))
  
      }

        const url = window.URL.createObjectURL(
          new Blob([blob]),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `Expediente-${data.n_reclamo}.pdf`,
        );
    
        document.body.appendChild(link);
    
        link.click();
    
        link.parentNode.removeChild(link);
        setExpedienteloading(false)
        toast.success("Descarga exitosa.")
      });


    } catch (error) {
      // Sentry.captureException(error);
    }
  }
}
