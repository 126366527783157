import React from 'react';
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import Table from "react-bootstrap/Table"
import { useSelector } from "react-redux"

const ModalExistente = ({ShowModal, setShowModal, Folio, TipoModal, handleAntecedentes, handleRecursos}) => {
    
  const Existentes = useSelector(state => state.Antecedentes.Existentes)
  const Reclamos = useSelector(state => state.ReclamosTramites.currentTramites)

  const handleRedirect = () => {

    let DataF = Reclamos.filter(func => Number(func.n_reclamo) === Number(Folio) && func.intendencia === localStorage.getItem('intendencia'));


    if(TipoModal === 'recurso'){
        handleRecursos(Folio, DataF[0].intendencia)
    }else{
        handleAntecedentes(Folio, DataF[0].intendencia)
    }
  }

    return ( 

        <>

        {Existentes.lenght !== 0 && 

    <Modal
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    show={ShowModal}
    onHide={() => setShowModal(false)}
  >
    <Modal.Header >
      <Modal.Title id="contained-modal-title-vcenter">
        Información Importante
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>

        {TipoModal === 'recurso' ? 
      <p className="my-2 text-justify">Ya posees los siguientes folios de recursos asociados al reclamo N° {Folio}, los cuales se agregarán al expediente luego de ser validados :</p>
      : <p className="my-2 text-justify">Ya posees los siguientes folios de antecedentes adicionales asociados al reclamo N° {Folio}, los cuales se agregarán al expediente luego de ser validados :</p>
        }

      <Table striped bordered hover className="w-50 mx-auto">
      <thead>
         
            <tr>
              <th>Fecha Ingreso</th>
              <th>Folio</th>
            </tr>
          </thead>
          <tbody>
            {Existentes.map((dato, i) => (
              <tr key={i}>
                <td className="align-middle">{dato.fecha_ingreso}</td>
                <td className="align-middle">{dato.folio}</td>
              </tr>
            ))}
          </tbody>

      </Table>

      <p className="my-2 text-center">¿Deseas continuar para ingresar otro documento?</p>
    </Modal.Body>
    <Modal.Footer>
      <Button onClick={() => handleRedirect()} className="d-flex mx-auto btn-login-ss">Sí, deseo ingresar otro</Button>
      <Button onClick={() => setShowModal(false)} className="d-flex mx-auto btn-danger">No, ya ingresé lo necesario</Button>
    </Modal.Footer>
  </Modal>

            }
  </>

     );
}
 
export default ModalExistente;