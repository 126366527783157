import clienteAxios from "../../Utils/axios"
import { GET_FOLIO_ANTECEDENTE_RESULT, VERIFICAR_AR_F, CLOSE_RECLAMO } from "../types"
import { toast } from "react-toastify"
import { navigate } from "gatsby"

export function EnviarAntecedentes(
  Data,
  Archivos,
  DataUsuario,
  setAntecedentesForm,
  setEnviando,
  setArchivos,
  tipoingreso,
  EmailUsuario,
  from
) {
  return async dispatch => {
    try {
      const tokenaccess = localStorage.getItem("token")

      const dataForm = new FormData()
      dataForm.append("intendencia", DataUsuario.intendencia)
      dataForm.append("tipo_ingreso", tipoingreso)
      dataForm.append("sistema_origen", DataUsuario.sistema_origen)
      dataForm.append("n_reclamo", DataUsuario.n_reclamo)
      dataForm.append("fecha_ingreso", DataUsuario.fecha_ingreso)
      dataForm.append("folder_id", DataUsuario.folder_id)
      dataForm.append("tipo_reclamo", DataUsuario.tipo_reclamo)
      dataForm.append("run_rec", DataUsuario.run_rec)
      dataForm.append("nombre_rec", DataUsuario.nombre_rec)
      dataForm.append("run_rep", DataUsuario.run_rep)
      dataForm.append("nombre_rep", DataUsuario.nombre_rep)
      dataForm.append("nombre_prestador", DataUsuario.nombre_prestador)
      dataForm.append("nombre_aseguradora", DataUsuario.nombre_aseguradora)
      dataForm.append("region_rec", DataUsuario.region_rec)
      dataForm.append("region_rep", DataUsuario.region_rep)
      dataForm.append("correo_ficha", EmailUsuario.email)
  
      if (tipoingreso === "Antecedente") {
        if (DataUsuario.intendencia === "IF") {
          dataForm.append("descripcion", Data.motivo_presentacion)
        } else {
          dataForm.append("motivoPresentacion", Data.motivo_presentacion)
        }

        for (var i = 0; i < Archivos.length; i++) {
          dataForm.append("file_" + i, Archivos[i].file, Archivos[i].name)
        }


      } else if (tipoingreso === "Recurso") {
        

        if (DataUsuario.intendencia === "IF") {
          dataForm.append("numero_oficio", Data.numero_oficio)
          dataForm.append("fechaDia", Data.fechaDia)
          dataForm.append("autoridadOficio", Data.autoridadOficio)

          dataForm.append("administrativo1", Data.administrativo1)
          dataForm.append("administrativo2", Data.administrativo2)
          dataForm.append("administrativo3", Data.administrativo3)
          dataForm.append("administrativo4", Data.administrativo4)
          dataForm.append("arbitral1", Data.arbitral1)
          dataForm.append("arbitral2", Data.arbitral2)
          dataForm.append("arbitral3", Data.arbitral3)
          dataForm.append("arbitral4", Data.arbitral4)

          dataForm.append("motivo", Data.motivo)
          dataForm.append("solicitud", Data.solicitud)
          dataForm.append("recCtr", Data.recCtr)
          
          dataForm.append("is_recurso_upload", false)
          dataForm.append("is_recurso_upload_str", 'Ya se agregó un recurso')
        } else {
          dataForm.append("numResolucion", Data.numResolucion)
          dataForm.append("fechaResolucion", Data.fechaResolucion)
          dataForm.append("motivoRecurso", Data.motivoRecurso)
          dataForm.append("peticionConcreta", Data.peticionConcreta)
        }

        for (var x = 0; x < Archivos.length; x++) {
          dataForm.append("file_" + x, Archivos[x].file, Archivos[x].name)
        }

      }

      if(DataUsuario.sistema_origen === "APB"){
        dataForm.append("id_reclamo_apb", DataUsuario.id_reclamo_apb)
      }else{
        dataForm.append("id_reclamo_apb", DataUsuario.unid)
      }

      let endpoint = 'IngAntecRecurAlReclamo/'
      
      console.log(DataUsuario.is_automatizado)
      console.log(tipoingreso)
      
      if( DataUsuario.is_automatizado && tipoingreso === "Recurso"){
        endpoint = 'IngresoRecurso/'
      }

      if( DataUsuario.is_automatizado && tipoingreso === "Antecedente"){
        endpoint = 'IngresoAntecedente/'
      }
      
      const respuesta = await clienteAxios.post(
        endpoint,
        dataForm,
        {
          headers: {
            Authorization: "Bearer " + tokenaccess,
            accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )
      
      if (respuesta.status === 200) {
        if (tipoingreso === "Antecedente") {
          toast.success("Documentación ingresada con éxito.")
          setAntecedentesForm({
            motivo_presentacion: "",
          })
          setArchivos([])

          
        
        localStorage.setItem("exitoso", 'Antecedente ingresado con éxito, con el folio '+respuesta.data.folio+".")
        if(from === 'detalle'){
          navigate("/inicio/tramites/detalle/")
        }else if(from === 'reclamos'){
          navigate("/inicio/reclamos/antecedentes/")
        }

        } else if (tipoingreso === "Recurso") {
          if (DataUsuario.intendencia === "IF") {
            setAntecedentesForm({
              motivo: "",
              solicitud: "",
              numero_oficio: "",
              fechaDia: "",
              autoridadOficio: "",
              administrativo1: "",
              administrativo2: "",
              administrativo3: "",
              administrativo4: "",
              arbitral1: "",
              arbitral2: "",
              arbitral3: "",
              arbitral4: "",
            })
            setArchivos([])
          } else {
            setAntecedentesForm({
              numResolucion: "",
              fechaResolucion: "",
              motivoRecurso: "",
              peticionConcreta: "",
            })
            setArchivos([])
          }

          
        localStorage.setItem("exitoso", 'Recurso Presentado con éxito, con el folio '+respuesta.data.folio+".")
        localStorage.setItem("exitoso_str", 'Antecedente ingresado con éxito.')

        // if(endpoint == 'IngresoRecurso/'){
        //   dispatch(Close_Reclamo_F(DataUsuario.n_reclamo))
        // }

        dispatch(Close_Reclamo_F(DataUsuario.n_reclamo))
        if(from === 'detalle'){
          navigate("/inicio/tramites/detalle/")
        }else if(from === 'reclamos'){
          navigate("/inicio/reclamos/recurso/")
        }
        }

        dispatch(Get_Folio_F(DataUsuario.n_reclamo))
        localStorage.setItem("folio", DataUsuario.n_reclamo)
        localStorage.setItem("intendencia", DataUsuario.intendencia)
      

      } else {
        toast.error("Error al enviar formulario.")
      }

      setEnviando(false)
    } catch (error) {
      
      console.log(error)
      if(error.response.status === 501) {
        toast.warning("Ya se agregó un Recurso a este reclamo.")
      }else if (error.response.status === 502){
        toast.error("(H01) Error con la conexión al servidor, intente más tarde.")
      }else if (error.response.status === 503){
        toast.error("(H02) Error con la conexión al servidor, intente más tarde.")
      }else if (error.response.status === 504){
        toast.error("(H03) Error con la conexión al servidor, intente más tarde.")
      }else if (error.response.status === 505){
        toast.error("(H04) Error con la conexión al servidor, intente más tarde.")
      }else if (error.response.status === 506){
        toast.error("(H05) Error con la conexión al servidor, intente más tarde.")
      }else{
        toast.error("Error con la conexión al servidor, intente más tarde.")
      }

      setEnviando(false)
      if (tipoingreso === "Antecedente") {
        setAntecedentesForm({
          motivo_presentacion: "",
        })
        setArchivos([])
      } else {
        if (DataUsuario.intendencia === "IF") {
          setAntecedentesForm({
            motivo: "",
            solicitud: "",
            numero_oficio: "",
            fechaDia: "",
            autoridadOficio: "",
            administrativo1: "",
            administrativo2: "",
            administrativo3: "",
            administrativo4: "",
            arbitral1: "",
            arbitral2: "",
            arbitral3: "",
            arbitral4: "",
          })
          setArchivos([])
        } else {
          setAntecedentesForm({
            numResolucion: "",
            fechaResolucion: "",
            motivoRecurso: "",
            peticionConcreta: "",
          })
          setArchivos([])
        }
      }
    }
  }
}

export function CleanFolioAntecedente() {
  return dispatch => {
    dispatch(Get_Folio_F(0))
  }
}

const Get_Folio_F = datos => ({
  type: GET_FOLIO_ANTECEDENTE_RESULT,
  payload: datos,
})



export function VerificarExistentesAR(
  Data,
  solicitado,
  setShowModal,
  handleAntecedentes, 
  handleRecursos
) {
  return async dispatch => {
    try {
      
      dispatch(Existentes_F([]))
      const tokenaccess = localStorage.getItem("token")
      
      let folio = Data[0].n_reclamo
      let run = Data[0].run_rec
      var tipo = ""

      if(Data[0].intendencia === "IF"){
        
          if(solicitado === "antecedente"){   
            tipo = "antecedentes_if_temp";
          }else{
            tipo = "recursos_if_temp";
          }
      }else{
        if(solicitado === "antecedente"){
          tipo = "antecedentes_ip_temp";
        }else{
          tipo = "recursos_ip_temp";
        }
      }

      const respuesta = await clienteAxios.get(`AntecRecurAlReclamo/${folio}/${run}/${tipo}/`,
        {
          headers: {
            Authorization: "Bearer " + tokenaccess,
            accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      )

      // if( Data[0].intendencia === "IF" && solicitado !== "antecedente" && ( respuesta.status === 200 || respuesta.status === 204 )){
        // console.log(2)
        // dispatch(Close_Reclamo_F(Data[0].n_reclamo))
      // }

      if(respuesta.status === 200){
        if(respuesta.data.length !== 0){
          setShowModal(true)
          dispatch(Existentes_F(respuesta.data))
        }else{
          setShowModal(false)
          dispatch(Existentes_F([]))
            if(solicitado === 'antecedente'){
            handleAntecedentes(folio, Data[0].intendencia)
          }else{
            handleRecursos(folio, Data[0].intendencia)
          }
        }

      }else if(respuesta.status === 204){
        dispatch(Existentes_F([]))

        if(solicitado === 'antecedente'){
          handleAntecedentes(folio, Data[0].intendencia)
        }else{
          handleRecursos(folio, Data[0].intendencia)
        }
      }

    }
    
   catch (error) {
    console.log(error)
    setShowModal(false)
    dispatch(Existentes_F([]))

    }

}
}

const Existentes_F = datos => ({
  type: VERIFICAR_AR_F,
  payload: datos,
})

const Close_Reclamo_F = ( folio ) => ({
  type: CLOSE_RECLAMO,
  folio: folio,
})
